import React, { ComponentType, FunctionComponent, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Components } from 'react-markdown/src/ast-to-react';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

export interface MarkdownProps {
  children: string,
  /**
   * @property className is needed so that the Markdown component
   * can be wrapped in a styled-component by using the css macro
   * and applying styles underneath
   * */
  className?: string,
  disallowedElements?: string[],
  components?: {
    [key in keyof JSX.IntrinsicElements]?: ComponentType
  },
}

const Markdown: FunctionComponent<MarkdownProps> = ({
  children,
  className,
  disallowedElements,
  components,
}) => {
  const disallowParagraph = useMemo(() => {
    if (typeof children === 'string' && /.*\n\n.*/.test(children)) {
      return [];
    }

    return ['p'];
  }, [children]);

  const mergedDisallowElements = useMemo(() => disallowParagraph.concat(disallowedElements ?? []), [disallowParagraph, disallowedElements]);

  return (
    <ReactMarkdown
      className={className}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      disallowedElements={mergedDisallowElements}
      components={components as Components}
      unwrapDisallowed
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
