import { css } from 'styled-components';
import {
  getSpacing, getTypography, SpacingName, TypographyName, ThemedFunctor,
} from '@doveit/lego';

export const errorPage: ThemedFunctor = ({ theme }) => css`
  text-align: center;
  height: 50vh;
  ${getSpacing.padding(theme, [SpacingName.BIG, 0])}
`;

export const errorTitle: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.H2)}
  ${getSpacing.marginBottom(theme, SpacingName.MEDIUM)}
`;

export const errorDescription: ThemedFunctor = ({ theme }) => css`
  ${getTypography(theme, TypographyName.BODY)}
  ${getSpacing.marginBottom(theme, SpacingName.MEDIUM)}
`;
