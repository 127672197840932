import React from 'react';
import { Button, ButtonColor, ButtonSize } from '@doveit/lego';
import { NextPage } from 'next';
import AppLayout from '../client/layouts/app-layout/AppLayout';
import data from '../data/pages/error.yml';
import * as styles from '../client/templates/_error.style';
import PageHead from '../client/components/page-head/PageHead';

export interface ErrorPageProps {
  statusCode?: number,
  error?: {
    message?: string,
    statusCode?: number,
    to?: {
      url?: string,
      as?: string,
      label?: string,
    },
  },
}

const isNotFound = (statusCode) => (statusCode === 404);

const ErrorPage: NextPage<ErrorPageProps> = ({
  statusCode = 404,
  error,
}) => (
  <AppLayout>
    <PageHead
      SEO={{
        ...data.SEO,
        robots: ['noindex', 'nofollow'],
      }}
    />
    <div css={styles.errorPage}>
      <h2 css={styles.errorTitle}>
        {isNotFound(statusCode) ? (
          <>
            Pagina non trovata
          </>
        ) : (
          <>
            Si è verificato un errore inaspettato
          </>
        )}
      </h2>
      <p css={styles.errorDescription}>
        {isNotFound(statusCode) ? (
          <>
            La pagina che cerchi non esiste :( <br /> Torna in home e continua a navigare su Dove.it
          </>
        ) : (
          <>
            Non è colpa tua, scusaci per il disagio. <br /> Prova a ricaricare la pagina o torna in homepage.
          </>
        )}
      </p>
      <Button
        type="button"
        href={(error && error.to && error.to.as) || '/'}
        color={ButtonColor.SECONDARY}
        size={ButtonSize.MEDIUM}
        label={(error && error.to && error.to.label) || 'Torna alla home'}
      />
    </div>
  </AppLayout>
);

export default ErrorPage;
