import React from 'react';
import { TextAlign } from '../../../types';
import Markdown from '../../markdown/Markdown';
import * as styles from '../PageSection.style';

export interface PageSectionActionsProps {
  textAlign?: TextAlign,
  inline?: boolean,
  claim?: string,
  children: React.ReactNode,
}

const PageSectionActions: React.FC<PageSectionActionsProps> = ({
  children,
  textAlign = TextAlign.LEFT,
  inline,
  claim,
}) => (
  <div
    css={styles.actions}
    $inline={inline}
  >
    {claim && (
      <div
        css={styles.actionsClaim}
        $textAlign={textAlign}
        $inline={inline}
      >
        <Markdown>
          {claim}
        </Markdown>
      </div>
    )}
    <div
      css={styles.actionsList}
      $textAlign={textAlign}
    >
      {children}
    </div>
  </div>
);

export default PageSectionActions;
