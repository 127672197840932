import { GetServerSideProps, GetServerSidePropsContext, NextPage } from 'next';
import React from 'react';
import serializers from 'pino-std-serializers';
import ErrorPage from '../../../pages/_error';
import { getLogger } from '../../../logger/isomorphic';

interface ServerSideError {
  statusCode: number,
  message: string,
}

const logger = getLogger();

export function withGetServerSideError<P = {}, Q extends NodeJS.Dict<string | string[]> = {}>(
  getServerSideFn: GetServerSideProps<P, Q>,
) {
  return async function wrappedGetServerSideProps(
    ctx: GetServerSidePropsContext<Q>,
  ) {
    try {
      return await getServerSideFn(ctx);
    } catch (err) {
      const error: ServerSideError = {
        statusCode: err.statusCode || 500,
        message: err.message,
      };

      ctx.res.statusCode = error.statusCode;

      logger.error({
        ctx: {
          req: ctx.req ? serializers.req(ctx.req) : undefined,
          res: ctx.res ? serializers.res(ctx.res) : undefined,
          pathname: ctx.resolvedUrl,
          query: ctx.query,
          asPath: ctx.req.url,
        },
        err,
      }, err.message);

      return { props: { error } };
    }
  };
}

export function withError<P>(Page: NextPage<P>) {
  return function WrappedPage(props: P) {
    const { error } = props as P & { error?: ServerSideError };

    if (error) {
      return (
        <ErrorPage statusCode={error.statusCode} error={error} />
      );
    }

    return <Page {...props} />;
  };
}
