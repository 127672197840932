import React from 'react';
import { TextAlign } from '../../../types';
import * as styles from '../PageSection.style';

export interface PageSectionBlockProps {
  textAlign?: TextAlign,
  children: React.ReactNode,
}

const PageSectionBlock: React.FC<PageSectionBlockProps> = ({
  children,
  textAlign = TextAlign.LEFT,
}) => (
  <div
    css={styles.block}
    $textAlign={textAlign}
  >
    {children}
  </div>
);

export default PageSectionBlock;
