import React from 'react';
import { TypographyName } from '@doveit/lego';
import { TextAlign } from '../../../types';
import * as styles from '../PageSection.style';

export type PageSectionTitleProps = React.PropsWithChildren<{
  textAlign?: TextAlign,
  size?: TypographyName,
  color?: string,
  content?: string,
  withBorder?: boolean,
}>;

const PageSectionTitle: React.FC<PageSectionTitleProps> = ({
  content,
  textAlign = TextAlign.LEFT,
  size = TypographyName.H2,
  color = 'neutrals.darkest',
  withBorder,
  children,
}) => (content
  ? (
    <h2
      css={styles.title}
      $color={color}
      $size={size}
      $textAlign={textAlign}
      $withBorder={withBorder}
      dangerouslySetInnerHTML={{
        __html: content,
      }}
    />
  )
  : (
    <h2
      css={styles.title}
      $color={color}
      $size={size}
      $textAlign={textAlign}
      $withBorder={withBorder}
    >
      {!content && children}
    </h2>
  )
);

export default PageSectionTitle;
