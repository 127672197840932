import React from 'react';
import { TypographyName } from '@doveit/lego';
import { TextAlign } from '../../../types';
import Markdown from '../../markdown/Markdown';
import * as styles from '../PageSection.style';

export interface PageSectionDescriptionProps {
  text?: string,
  size?: TypographyName,
  textAlign?: TextAlign,
  color?: string,
}

const PageSectionDescription: React.FC<PageSectionDescriptionProps> = ({
  textAlign = TextAlign.LEFT,
  size = TypographyName.BODY,
  color = 'neutrals.darkest',
  text,
}) => (
  <div
    css={styles.description}
    $textAlign={textAlign}
    $size={size}
    $color={color}
  >
    <Markdown>
      {text}
    </Markdown>
  </div>
);

export default PageSectionDescription;
