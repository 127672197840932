import { css } from 'styled-components';
import {
  getSpacing, SpacingName, getTypography, getColorPalette, FontWeight, BreakpointQueryName, ThemedFunctor, TypographyName,
} from '@doveit/lego';
import { TextAlign } from '../../types';

export const section: ThemedFunctor = ({
  theme,
  $padding,
  $backgroundColor,
  $backgroundImage,
  $backgroundGradient,
}) => css`
  ${getSpacing.padding(theme, $padding)}
  background-color: ${getColorPalette(theme, $backgroundColor)};

  ${$backgroundImage && `
    ${theme.breakpoints.up(BreakpointQueryName.LG)} {
      background-image: url(${$backgroundImage});
      background-position: center center;
      background-size: cover;
    }
  `}

  ${$backgroundGradient && `
    background-image: ${$backgroundGradient};
  `}
`;

export const title: ThemedFunctor = ({
  theme,
  $color,
  $size,
  $textAlign,
  $withBorder,
}) => css`
  ${getTypography(theme, $size)}
  color: ${getColorPalette(theme, $color)};
  font-weight: ${FontWeight.BOLD};

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    text-align: ${$textAlign};
  }

  ${$withBorder && css`
    ${getSpacing.paddingBottom(theme, SpacingName.SMALLEST)}
    border-bottom: 1px solid ${theme.palettes.neutrals.light};
  `}

  strong {
    color: ${theme.palettes.brand.primary};
  }
`;

export const description: ThemedFunctor = ({
  theme,
  $size,
  $color,
  $textAlign,
}) => css`
  ${getTypography(theme, $size)}
  font-weight: normal;
  color: ${getColorPalette(theme, $color)};

  h2 + & {
    ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
  }

  & p + p {
    ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
  }

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    text-align: ${$textAlign};
  }
`;

export const block: ThemedFunctor = ({
  theme,
  $textAlign,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.MEDIUM)}

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    text-align: ${$textAlign};
  }
`;

export const actions: ThemedFunctor = ({
  theme,
  $inline,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.BIG)}

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    ${$inline && `
      display: flex;
      align-items: center;
    `}
  }
`;

export const actionsList: ThemedFunctor = ({
  theme,
  $textAlign,
}) => css`
  flex-shrink: 0;

  & > a, button {
    flex-basis: 100%;
    width: 100%;
    margin-top: ${theme.spaceUnits.mobile.SMALLER};
    margin-left: 0 !important;

    &:first-child {
      margin-top: 0;
    }

    ${theme.breakpoints.up(BreakpointQueryName.MD)} {
      margin-top: 0;
      margin-right: ${theme.spaceUnits.desktop.SMALLER};
      width: 100%;
      margin-left: 0 !important;
      flex-basis: auto;
      width: auto;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    ${$textAlign === TextAlign.RIGHT && `
      justify-content: flex-end;
    `}

    ${$textAlign === TextAlign.CENTER && `
      justify-content: center;
    `}
  }
`;

export const actionsClaim: ThemedFunctor = ({
  theme,
  $textAlign,
  $inline,
}) => css`
  ${getTypography(theme, TypographyName.H4)}
  ${getSpacing.marginBottom(theme, SpacingName.SMALL)}
  font-weight: normal;
  flex-grow: 1;

  ${theme.breakpoints.up(BreakpointQueryName.MD)} {
    ${$inline && `
      margin-right: 1rem;
      margin-bottom: 0;
    `}

    ${$textAlign === TextAlign.RIGHT && `
      text-align: right;
    `}

    ${$textAlign === TextAlign.CENTER && `
      text-align: center;
    `}
  }
`;
