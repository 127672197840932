/* eslint-disable prefer-spread */
import { LogFn, Logger } from 'pino';

interface LogFunction extends LogFn {}

class IsomorphicLogger {
  private logger: Logger | Console;

  constructor(loggerInstance: Logger) {
    this.logger = loggerInstance;
  }

  log: LogFunction = (...args: any[]) => {
    // eslint-disable-next-line no-prototype-builtins
    const method = this.logger.hasOwnProperty('log')
      ? 'log'
      : 'info';

    this.logger[method].apply(this.logger, args);
  };

  debug: LogFunction = (...args: any[]) => {
    this.logger.debug.apply(this.logger, args);
  };

  info: LogFunction = (...args: any[]) => {
    this.logger.info.apply(this.logger, args);
  };

  warn: LogFunction = (...args: any[]) => {
    this.logger.warn.apply(this.logger, args);
  };

  error: LogFunction = (...args: any[]) => {
    this.logger.error.apply(this.logger, args);
  };
}

export function getLogger() {
  const logger = typeof window !== 'undefined' ? console : require('./server').default;
  return new IsomorphicLogger(logger);
}
