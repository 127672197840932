import React from 'react';
import { SpacingName } from '@doveit/lego';
import LayoutWrapper from '../../layouts/layout-wrapper/LayoutWrapper';
import PageSectionTitle from './title/PageSectionTitle';
import PageSectionDescription from './description/PageSectionDescription';
import PageSectionBlock from './block/PageSectionBlock';
import PageSectionActions from './actions/PageSectionActions';
import * as styles from './PageSection.style';

interface PageSectionComposition {
  Title: typeof PageSectionTitle,
  Description: typeof PageSectionDescription,
  Block: typeof PageSectionBlock,
  Actions: typeof PageSectionActions,
  Wrapper: typeof LayoutWrapper,
}

export interface PageSectionProps extends React.AriaAttributes {
  id?: string,
  padding?: SpacingName | SpacingName[],
  backgroundColor?: string,
  backgroundGradient?: string,
  backgroundImage?: string,
  children: React.ReactNode,
}

const PageSection: React.FC<PageSectionProps> & PageSectionComposition = ({
  id,
  padding = [SpacingName.BIG, 0],
  backgroundColor = 'neutrals.lightest',
  backgroundImage = '',
  backgroundGradient,
  children,
  ...rest
}) => (
  <section
    {...rest}
    id={id}
    css={styles.section}
    $padding={padding}
    $backgroundColor={backgroundColor}
    $backgroundGradient={backgroundGradient}
    $backgroundImage={backgroundImage}
  >
    {children}
  </section>
);

PageSection.Title = PageSectionTitle;
PageSection.Description = PageSectionDescription;
PageSection.Block = PageSectionBlock;
PageSection.Actions = PageSectionActions;
PageSection.Wrapper = LayoutWrapper;

export default PageSection;
